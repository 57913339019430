export const FeatureFlagEnum = {
  ShowCodeAssistantChat: 'WEBAPP__APP__ShowCodeAssistantChat',
  ShowBambooChat: 'WEBAPP__APP__ShowBambooChat',
  ShowInfoMessage: 'WEBAPP__Frontend__ShowInfoMessage',
  UsePatronousAI: 'WEBAPP__APP__UsePatronousAIForChat',
  ShowCustomRecallApp: 'WEBAPP__APP__ShowCustomRecallApp',
  ShowBHPStandardsApp: 'WEBAPP__APP__ShowBHPStandardsApp',
  ShowRioTintoStandardsApp: 'WEBAPP__APP__ShowRioTintoStandardsApp',
  ShowAusNetStandardsApp: 'WEBAPP__APP__ShowAusNetStandardsApp',
  ShowHomepageSlides: 'WEBAPP__Frontend__ShowHomepageSlides',
  ShowSharePointFilePicker: 'WEBAPP__ShowSharePointFilePicker',
  ShowPMApp: 'WEBAPP__APP__ShowPMApp',
}
