import { FC, useCallback, useEffect, useState } from 'react'
import RecallModal from '../common/RecallModal'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import { FullScreen } from '../../stores/AppStore'
import { useRecoilValue } from 'recoil'
import RecallDropdown from '../common/RecallDropdown'
import { useNomicToken } from '../../hooks/useNomicToken'
import { IAddToastPros, IDropdownItemProps, useToast } from '@aurecon-creative-technologies/styleguide'
import { createOrganisation, getNomicOrganisations } from '../../api/CustomRecallAppService'
import Style from '../../styles/NomicModal.module.sass'
import { ResponseData } from '../../models/api/IResponse'
import RecallFormInput from '../common/RecallFormInput'
import { nomicOrgModalSchema } from '../../validators/nomicOrgModaValidator'
import { getErrorsFromValidationResult, IErrorModel } from '../../validators/commonValidator'

interface ICreateNomicOrgModalProps {
  open: boolean
  onClose: () => void
}

const NEW_ORG_ID = 1

const EMPTY_ORG_LIST = [
  { id: NEW_ORG_ID, label: <span className={Style.dropdownCustomItem}>+ Create New Organisation</span> },
]

const CreateNomicOrgModal: FC<ICreateNomicOrgModalProps> = (props) => {
  const { open, onClose } = props
  const [errors, setErrors] = useState<IErrorModel>({})
  const fullScreen = useRecoilValue(FullScreen)
  const [nomicOrgs, setNomicOrgs] = useState<IDropdownItemProps[]>(EMPTY_ORG_LIST)
  const [selectedOrgId, setSelectedOrgId] = useState<string | number>()
  const [newOrgName, setNewOrgName] = useState<string>('')
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const [creatingOrg, setCreatingOrg] = useState<boolean>(false)
  const { nomicToken } = useNomicToken(ChatTypeEnum.CUSTOM_RECALL_APP)
  const { addToast } = useToast()

  const fetchAndSetOrgs = useCallback(async () => {
    if (!nomicToken) return

    setLoadingList(true)

    const orgs = ResponseData(await getNomicOrganisations({ nomicToken }))
    const list = [...EMPTY_ORG_LIST, ...(orgs?.map((o) => ({ id: o.organization_id, label: o.slug })) || [])]
    setNomicOrgs(list)
    setLoadingList(false)
  }, [nomicToken])

  useEffect(() => {
    if (!nomicToken) return
    fetchAndSetOrgs()
  }, [fetchAndSetOrgs, nomicToken])

  const onOrgNameChange = (orgName: string) => {
    setNewOrgName(orgName)
    const validationResult = nomicOrgModalSchema().validate({ newOrgName: orgName }, { abortEarly: false })
    const errs = getErrorsFromValidationResult(validationResult)
    setErrors(errs)
  }

  const handleModalClose = () => {
    setErrors({})
    setSelectedOrgId('')
    setNewOrgName('')
    onClose()
  }

  const handleNext = async () => {
    if (selectedOrgId === NEW_ORG_ID && newOrgName) {
      setCreatingOrg(true)
      const createOrgRes = ResponseData(await createOrganisation({ nomicToken, orgName: newOrgName }))

      setCreatingOrg(false)

      const timeout = 5000
      const toast: IAddToastPros = createOrgRes?.success
        ? { type: 'success', message: `Organisation ${newOrgName} created successfully.`, timeout }
        : { type: 'error', message: 'Cannot create an organisation. Please try again.', timeout }

      addToast(toast)
      await fetchAndSetOrgs()
      setSelectedOrgId(createOrgRes?.organization_id ?? '')
    }
    handleModalClose()
  }

  return (
    <RecallModal
      chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
      isShowing={open}
      onSave={handleNext}
      onClose={handleModalClose}
      disabled={(selectedOrgId === NEW_ORG_ID || !selectedOrgId) && (!newOrgName || !!Object.values(errors).length)}
      size={fullScreen ? 'medium' : 'small'}
      labelYes='Next'
      loadingYes={loadingList || creatingOrg}
      cssClass={Style.nomicOrgModal}
    >
      <div className={Style.contentWrapperNomicModal}>
        <h2>Create</h2>
        {selectedOrgId !== NEW_ORG_ID ? (
          <RecallDropdown
            chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
            items={nomicOrgs}
            onSelectItem={setSelectedOrgId}
            selectedItem={selectedOrgId}
            disabled={creatingOrg}
            label='Select Organisation'
            required={true}
          />
        ) : (
          <RecallFormInput
            chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
            value={newOrgName}
            onChange={onOrgNameChange}
            placeholder='Enter organisation name...'
            label='Organisation name'
            required={true}
            error={errors.newOrgName?.[0]}
          />
        )}
      </div>
    </RecallModal>
  )
}

export default CreateNomicOrgModal
