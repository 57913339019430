export const LevelEnum = {
  UP: 0,
  SITE: 1,
  DRIVE: 2,
  ROOT: 3,
  FOLDER: 4,
  FILE: 5,
}

export const LevelLabel = {
  [LevelEnum.UP]: 'Level Up',
  [LevelEnum.SITE]: 'Site',
  [LevelEnum.DRIVE]: 'Drive',
  [LevelEnum.ROOT]: 'Root',
  [LevelEnum.FOLDER]: 'Folder',
  [LevelEnum.FILE]: 'File',
}
