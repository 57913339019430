import { selector, atom } from 'recoil'
import {
  getAppConfig,
  getAppFlags,
  getAppLanguages,
  getAppVersion,
  getCapabilities,
  getLoginLanguages,
  getSystemBannersInfo,
} from '../api/AppService'
import { IChatSession, IFocusItem, INewCapabilities, IQuestion } from '../models/IQuestionModels'
import { ResponseData } from '../models/api/IResponse'
import { getAllCustomApps } from '../api/CustomRecallAppService'
import { COOKIE_LOGIN_LANGUAGES } from '../config/config'
import { ILangJson } from '../hooks/useLanguages'
import { ICustomAppResponseModel } from '../models/api/ICreateChatModels'
import { getAppSlides } from '../api/HomepageService'
import { getUserPermissions } from '../api/UserService'

const RECALL_VERSION_EMPTY_RESPONSE = { name: 'Recall', version: '0.0.0' }

export const AppVersion = selector({
  key: 'version',
  get: async () => {
    const response = ResponseData(await getAppVersion({}))
    return response || RECALL_VERSION_EMPTY_RESPONSE
  },
})

export const AppFlags = selector({
  key: 'app_flags',
  get: async () => {
    const response = ResponseData(await getAppFlags({}))
    return response || {}
  },
})

export const CustomAppsLoader = selector({
  key: 'custom_apps_loader',
  get: async () => {
    const response = ResponseData(await getAllCustomApps({}))
    return response
  },
})

export const AppLanguages = selector({
  key: 'app_languages',
  get: async () => {
    const response = await getAppLanguages({})
    return response?.data || null
  },
})

export const AppSlides = selector({
  key: 'app_slides',
  get: async () => {
    const response = await getAppSlides({})
    return response?.data || null
  },
})

export const CapabilitiesLoader = selector({
  key: 'capabilities_loader',
  get: async () => {
    const response = await getCapabilities({})
    return response?.data ?? null
  },
})

const ONE_MINUTE = 60000
const ONE_DAY = 24 * 60 * ONE_MINUTE

const expiry = ONE_DAY

export const LoginLanguages = selector<ILangJson[] | null>({
  key: 'login_languages',
  get: async () => {
    const cookieLangs = localStorage.getItem(COOKIE_LOGIN_LANGUAGES)

    const date = new Date().valueOf()
    if (cookieLangs) {
      // if the translations in the local storage have not expired, return them here
      const data = JSON.parse(cookieLangs)
      if (data.date && Number(data.date) > date - expiry) return data.langs
    }

    const response = await getLoginLanguages()
    if (response.success) {
      localStorage.setItem(COOKIE_LOGIN_LANGUAGES, JSON.stringify({ langs: response.data, date }))
      return response.data
    }
    return null
  },
})

export const SystemBannersLoader = selector({
  key: 'system_banners_loader',
  get: async () => {
    const response = await getSystemBannersInfo({})
    return response?.data || null
  },
})

export const TermsModal = atom({
  key: 'terms_modal',
  default: false,
})

export const QuestionInput = atom({
  key: 'question_input',
  default: '',
})

export const SelectedFocus = atom<IFocusItem[]>({
  key: 'selected_focus',
  default: [],
})

export const ChatSession = atom<IChatSession | null>({
  key: 'chat_session',
  default: null,
})

export const NomicSession = atom<ICustomAppResponseModel | null>({
  key: 'nomic_session',
  default: null,
})

export const NotFound = atom<boolean>({
  key: 'not_found',
  default: false,
})

export const ShowPreparingChat = atom<boolean>({
  key: 'show_preparing_chat',
  default: false,
})

export const LoadingAnswer = atom<boolean>({
  key: 'loading_answer',
  default: false,
})

export const CurrentQuestion = atom<IQuestion | null>({
  key: 'current_question',
  default: null,
})

export const ShowEditModal = atom<boolean>({
  key: 'show_edit_modal',
  default: false,
})

export const ShowFeedbackModal = atom<boolean>({
  key: 'show_feedback_modal',
  default: false,
})

export const ShowEditHistoryModal = atom<boolean>({
  key: 'show_edit_history_modal',
  default: false,
})

export const ShowDownloadFileModal = atom<boolean>({
  key: 'show_download_file_modal',
  default: false,
})

export const ShowSeeQuestionModal = atom<boolean>({
  key: 'show_see_question_modal',
  default: false,
})

export const ScrollChat = atom({
  key: 'scroll_chat',
  default: 0,
})

export const PageBannerActive = atom<boolean>({
  key: 'page_banner_active',
  default: true,
})

export const ShowExtendedInput = atom({
  key: 'show_extended_input',
  default: false,
})

export const FullScreen = atom({
  key: 'full_screen',
  default: true,
})

export const Language = atom({
  key: 'language',
  default: 'en',
})

export const QuestionFile = atom({
  key: 'question_file',
  default: null as File[] | null,
})

export const ChatType = atom({
  key: 'chat_type',
  default: null as number | null,
})

export const FocusOnInput = atom({
  key: 'focus_on_input',
  default: 0,
})

export const QuestionCapabilities = atom<INewCapabilities[]>({
  key: 'capabilities',
  default: [],
})

export const BannerInfo = atom<{ height: number }>({
  key: 'close_banner',
  default: { height: 0 },
})

export const TriggerScroll = atom<boolean>({
  key: 'trigger_scroll',
  default: true,
})

export const AppConfig = selector({
  key: 'app_config',
  get: async () => {
    const response = await getAppConfig({})
    return response?.data ?? null
  },
})

export const PermissionsLoader = selector({
  key: 'permissions_loader',
  get: async () => {
    const response = await getUserPermissions({})
    return response?.data ?? null
  },
})
export const UserPermissions = atom<string[]>({
  key: 'user_permissions',
  default: [],
})
