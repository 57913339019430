import { FC, useEffect, useMemo } from 'react'
import { useSetRecoilState, useRecoilState } from 'recoil'
import { Grid, Icon } from '@aurecon-creative-technologies/styleguide'

import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'
import PreparingChatModal from '../components/modals/PreparingChatModal'
import { ChatSession, ChatType, QuestionFile } from '../stores/AppStore'
import { Trans } from 'react-i18next'
import { useLanguages } from '../hooks/useLanguages'
import LoadingScreen from '../components/LoadingScreen'

import Style from '../styles/Home.module.sass'
import ChatSwitcher from '../components/ChatSwitcher'
import { chatStandardConfig } from '../config/chatStandardsConfig'

interface ChatStandardsHomeProps {
  appRoute: string
}

const ChatStandardsHome: FC<ChatStandardsHomeProps> = ({ appRoute }) => {
  const config = useMemo(() => chatStandardConfig[appRoute], [appRoute])

  const setChatType = useSetRecoilState(ChatType)
  const setQuestionFile = useSetRecoilState(QuestionFile)
  const [chatSession, setChatSession] = useRecoilState(ChatSession)
  const { t, i18n } = useLanguages()
  if (appInsights) appInsights.trackPageView({ name: config.trackPageView })

  useEffect(() => {
    if (!chatSession) return
    setChatSession(null)
  }, [setChatSession, chatSession])

  useEffect(() => {
    setChatType(config.chatTypeEnum)
    setQuestionFile(null)

    return () => setChatType(null)
  }, [setChatType, setQuestionFile, config])

  if (!i18n)
    return (
      <Page>
        <LoadingScreen text={t('loading_translation')} />
      </Page>
    )

  return (
    <Page menu contentWrapper>
      <div className={Style.pageContents}>
        <h1 className={Style.chatTitle}>{config.title}</h1>
        <ChatSwitcher activeChat={config.chatTypeEnum} />
        <p>{t(config.descriptions[0])}</p>
        <p>{t(config.descriptions[1])}</p>
        <div className={Style.examples}>
          <h3>{t('navigation_tip')}</h3>
          <Grid row>
            <div>
              <p>
                <Icon type='keyboard' outlined />
              </p>
            </div>
            <div className={Style.tip}>
              <p>
                <Trans i18nKey='navigation' />
              </p>
            </div>
          </Grid>

          <Grid row>
            <div>
              <p>
                <Icon type='post_add' outlined />
              </p>
            </div>
            <div className={Style.tip}>
              <p>{t(config.descriptions[2])}</p>
            </div>
          </Grid>
        </div>
      </div>
      <PreparingChatModal />
    </Page>
  )
}

export default ChatStandardsHome
