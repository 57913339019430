export const BambooPropertyEnum = {
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
}

export const NomicDataSourceEnum = {
  EXISTING_NOMIC: 1,
  NEW_NOMIC_MAP: 2,
}
