import { useState, useEffect, FC } from 'react'
import { FileIcon, defaultStyles } from 'react-file-icon'
import { GetMSFile, IMSGraphFile } from '../../api/MSGraphService'
import { LevelEnum } from '../../enums/LevelEnum'
import { Button, Checkbox, Grid, Icon, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import Style from '../../styles/TableFileBrowser.module.sass'
import nextId from 'react-id-generator'
import { usefileDownload } from '../../hooks/useFileDownload'

interface ITableFileBrowserProps {
  locations: IMSGraphFile[]
  files: IMSGraphFile[]
  onRowClick: (item: IMSGraphFile) => Promise<void>
  onBreadcrumbClick: (index: number) => Promise<void>
  onCloseModal: () => void
  driveId: string | null
}

const TableFileBrowser: FC<ITableFileBrowserProps> = (props) => {
  const { files, locations, onRowClick, onBreadcrumbClick, onCloseModal, driveId } = props
  const [selectedFiles, setSelectedFiles] = useState<string[]>([])
  const [isNextEnabled, setIsNextEnabled] = useState(false)
  const { downloadFile } = usefileDownload()

  useEffect(() => {
    const hasSelectedFiles = !!selectedFiles.length
    setIsNextEnabled(hasSelectedFiles)
  }, [selectedFiles])

  const handleCheckboxClick = (file: IMSGraphFile) => {
    const isSelected = selectedFiles.includes(file.id)

    if (isSelected) {
      setSelectedFiles(selectedFiles.filter((id) => id !== file.id))
      return
    }
    setSelectedFiles([...selectedFiles, file.id])
  }

  const handleFileClick = (item: IMSGraphFile) => {
    if (item.type === LevelEnum.FILE) return

    onRowClick(item)
  }

  const handleNextClick = async () => {
    if (!selectedFiles.length) return
    const selectedFileData = files.filter((file) => selectedFiles.includes(file.id))

    for (const file of selectedFileData) {
      const blob = await GetMSFile({ driveId: driveId ?? '', itemId: file.id })

      blob ? downloadFile(blob, file.name ?? '') : console.error(`Error fetching content for ${file.name}`)
    }
  }

  const handleCancelClick = () => {
    setSelectedFiles([])
    onCloseModal()
  }

  const formatDate = (dateString: string) => {
    if (!dateString) {
      return 'N/A'
    }

    const date = new Date(dateString)
    if (isNaN(date.getTime())) {
      return 'N/A'
    }

    return new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }).format(date)
  }

  const renderTable = () => {
    return (
      <Grid row gap={12}>
        <Grid item xs={12}>
          <Table headers={[{ label: '' }, { label: 'Name' }, { label: 'Modified By' }, { label: 'Modified' }]}>
            {files.map((item) => {
              const itemName = item.name ?? ''
              const extn = itemName.split('.').length > 1 ? itemName.split('.').pop() : undefined
              const iconProps = extn ? defaultStyles[extn] : undefined
              return (
                <TableRow key={item.id} onClick={() => handleFileClick(item)}>
                  <TableCell cellClass={Style.checkBoxCell}>
                    {item.type === LevelEnum.FILE && (
                      <Checkbox checked={selectedFiles.includes(item.id)} onChange={() => handleCheckboxClick(item)} />
                    )}
                  </TableCell>
                  <TableCell>
                    <div className={Style.iconCell}>
                      <span className={Style.fileIcon}>
                        {extn && iconProps ? <FileIcon extension={extn} {...iconProps} /> : <Icon type='folder' />}
                      </span>
                      {item.name}
                    </div>
                  </TableCell>
                  <TableCell>{item.lastModifiedBy?.user?.displayName ?? 'N/A'}</TableCell>
                  <TableCell>{formatDate(item.lastModifiedDateTime ?? '')}</TableCell>
                </TableRow>
              )
            })}
          </Table>
        </Grid>
      </Grid>
    )
  }

  const renderBreadcrumbs = () => {
    const breadcrumbs = [
      { name: 'Home', index: -1 },
      ...locations.map((loc, index) => ({
        name: loc.name,
        index,
      })),
    ]

    return (
      <div className={Style.breadcrumbs}>
        {breadcrumbs.map((crumb, idx) => (
          <span key={nextId()}>
            <Button type='text' onClick={() => onBreadcrumbClick(crumb.index)} label={crumb.name ?? ''} />
            {idx < breadcrumbs.length - 1 && ' > '}
          </span>
        ))}
      </div>
    )
  }

  const renderButtons = () => {
    return (
      <div className={Style.buttonContainer}>
        <Button cssClass={Style.actionBtn} label='Cancel' type='secondary' onClick={handleCancelClick} />
        <Button
          cssClass={Style.actionBtn}
          label='Download Selected'
          onClick={handleNextClick}
          disabled={!isNextEnabled}
        />
      </div>
    )
  }

  return (
    <div className={Style.fileBrowserLayout}>
      <h3>Sharepoint</h3>
      <div className={Style.mainContent}>
        {renderBreadcrumbs()}
        {renderTable()}
        {renderButtons()}
      </div>
    </div>
  )
}

export default TableFileBrowser
