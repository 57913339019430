import { FC, useRef, useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Loader } from '@aurecon-creative-technologies/styleguide'

import { appInsights } from '../api/AppInsights'
import { getChatQuestions } from '../api/QuestionService'
import { ChatSession, ChatType, NotFound, ScrollChat } from '../stores/AppStore'
import Page from '../components/Page'
import ChatQuestion from '../components/ChatQuestion'
import ChatAnswer from '../components/ChatAnswer'
import ChatNotFound from '../components/ChatNotFound'
import AnswerFeedbackModal from '../components/modals/AnswerFeedbackModal'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'
import AnswerEditModal from '../components/modals/AnswerEditModal'
import EditHistoryModal from '../components/modals/EditHistoryModal'
import FileDownloadModal from '../components/modals/FileDownloadModal'
import ChatSwitcher from '../components/ChatSwitcher'
import Style from '../styles/Home.module.sass'

const ChatPM: FC = () => {
  const [chatSession, setChatSession] = useRecoilState(ChatSession)
  const [notFound, setNotFound] = useRecoilState(NotFound)
  const setScrollChat = useSetRecoilState(ScrollChat)
  const setChatType = useSetRecoilState(ChatType)
  const contentsRef = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState(false)
  const { chatId } = useParams()

  const getSessionAsync = useCallback(async () => {
    const id = chatId ?? ''
    const questions = await getChatQuestions({ chatId: id })

    if (!questions?.data) {
      setLoading(false)
      setNotFound(true)
      setChatSession(null)
      return
    }

    setChatSession({
      chatId: id,
      questions: questions.data,
      type: ChatTypeEnum.PM_APP,
    })
    setLoading(false)
    setNotFound(false)

    setTimeout(() => {
      setScrollChat((s) => s + 1)
    }, 1000)
  }, [chatId, setChatSession, setNotFound, setScrollChat])

  useEffect(() => {
    if (chatSession && chatSession.type === ChatTypeEnum.PM_APP) return
    setChatSession(null)
  }, [setChatSession, chatSession])

  useEffect(() => {
    setChatType(ChatTypeEnum.PM_APP)

    return () => setChatType(null)
  }, [setChatType])

  useEffect(() => {
    if (chatSession?.chatId === chatId) return

    setLoading(true)
    getSessionAsync()
  }, [chatSession, chatId, setChatSession, setNotFound, setScrollChat, getSessionAsync])

  if (appInsights) appInsights.trackPageView({ name: 'PM Assistant Chat' })

  if (loading)
    return (
      <Page menu contentWrapper>
        <h1>PM Assistant</h1>
        <Loader label='Loading chat...' />
      </Page>
    )

  return (
    <Page menu contentWrapper contentsRef={contentsRef}>
      <h1 className={Style.chatTitle}>PM Assistant</h1>
      <ChatSwitcher activeChat={ChatTypeEnum.PM_APP} />
      {notFound && <ChatNotFound />}
      {chatSession?.questions.map((question) => {
        return (
          <div key={question.rowKey} id={`qa-holder-${question.rowKey}`}>
            <ChatQuestion question={question} />
            <ChatAnswer question={question} />
          </div>
        )
      })}
      <AnswerFeedbackModal />
      <AnswerEditModal />
      <EditHistoryModal />
      <FileDownloadModal />
    </Page>
  )
}

export default ChatPM
