import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import { PermissionsLoader, UserPermissions } from '../stores/AppStore'
import { useEffect } from 'react'

// uses permissions from auth0
export const useHasPermissions = () => {
  const permissionsLoader = useRecoilValueLoadable(PermissionsLoader)
  const [userPermissions, setUserPermissions] = useRecoilState(UserPermissions)

  useEffect(() => {
    if (permissionsLoader.state !== 'hasValue' || !permissionsLoader.contents) return

    setUserPermissions(permissionsLoader.contents)
  }, [permissionsLoader.contents, permissionsLoader.state, setUserPermissions])

  const hasPermissions = (action: string) => userPermissions.includes(action)

  return { hasPermissions, userPermissions, permissionsLoading: permissionsLoader.state === 'loading' }
}
