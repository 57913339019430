import { useRecoilValueLoadable } from 'recoil'
import { AppConfig } from '../stores/AppStore'
import { IAppConfig } from '../api/AppService'

export const useAppConfig = (): IAppConfig | null => {
  const appConfig = useRecoilValueLoadable(AppConfig)

  if (appConfig.state !== 'hasValue' || !appConfig.contents) return null
  return appConfig.contents ?? null
}
