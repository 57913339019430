import { useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { BannerInfo, Language, SystemBannersLoader } from '../stores/AppStore'
import { useEffect, useState } from 'react'
import { getLocalStorageItem, getTextFromHTML } from '../helpers/utils'
import { BANNER_CLOSED_KEY } from '../config/config'
import { ISystemBannerInfo } from '../api/AppService'
import { LanguageEnum } from '../enums/LanguageEnum'

export const useSystemBanner = () => {
  const [systemBanner, setSystemBanner] = useState<ISystemBannerInfo>()
  const systemBannersLoader = useRecoilValueLoadable(SystemBannersLoader)
  const appLanguage = useRecoilValue(Language)
  const bannerInfo = useRecoilValue(BannerInfo)

  useEffect(() => {
    if (systemBannersLoader.state !== 'hasValue' || !systemBannersLoader.contents) return
    let banner = systemBannersLoader.contents?.find((c) => c.language === appLanguage)

    const hasMessage = getTextFromHTML(banner?.message ?? '')
    if (!hasMessage) {
      banner = systemBannersLoader.contents.find((c) => c.language === LanguageEnum.ENGLISH)
    }

    if (!banner) return
    const bannerCache = getLocalStorageItem(BANNER_CLOSED_KEY)
    const enableSystemBanner = banner.enabled && bannerCache !== banner.lastModified

    const bannerInfo: ISystemBannerInfo = { ...banner, enabled: enableSystemBanner ?? false }
    setSystemBanner(bannerInfo)
  }, [appLanguage, systemBannersLoader.contents, systemBannersLoader.state, bannerInfo.height])

  return { systemBanner }
}
