import { useAuth0 } from '@auth0/auth0-react'
import { getPermissions } from '../config/permissions'
import { getUserRoles } from '../helpers/appRoles'
import { useAppConfig } from './useAppConfig'

export const useUserHasPermissions = (action: number): boolean => {
  const { user } = useAuth0()
  const roles = getUserRoles(user)
  const config = useAppConfig()

  return !!roles.find((role) => getPermissions(config?.APP_ENVIRONMENT ?? '', action).includes(role))
}
