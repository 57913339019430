import { LevelEnum } from '../enums/LevelEnum'
import { Drive, Site } from '@microsoft/microsoft-graph-types'
import { getMSGraphToken } from './UserService'
import { ResponseData } from '../models/api/IResponse'
import jwt_decode from 'jwt-decode'

interface IMSALProps {
  siteId?: string
  driveId?: string
  itemId?: string
}

export interface IMSGraphFile extends Site {
  type: number
  id: string
}

export interface IMSGraphDrive extends Drive {
  type: number
  id: string
}

let msToken: string | null = null

export const GRAPH_URL = 'https://graph.microsoft.com/v1.0'

const checkTokenExpiration = (tokenString: string) => {
  try {
    if (!tokenString) return true
    //Here we are checking for expiry token which is one possibility when 401 occurs
    const decoded: { exp: string } = jwt_decode(tokenString)
    const now = Date.now() / 1000
    const expiryString = decoded ? decoded.exp : null
    const expiryEpoch = expiryString ? parseInt(expiryString) : now

    return expiryEpoch < now
  } catch (error) {
    console.error("** Can't decode token...")
    console.error(error)
    return false
  }
}
export const getCachedMSToken = async () => {
  const isTokenExpired = msToken ? checkTokenExpiration(msToken) : true

  if (!isTokenExpired) return msToken

  const tokenRes = ResponseData(await getMSGraphToken({}))

  msToken = tokenRes?.azureToken ?? null

  return msToken
}

export const GetSites = async (): Promise<IMSGraphFile[]> => {
  const accessToken = await getCachedMSToken()
  if (!accessToken) return []

  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const sites = await fetch(`${GRAPH_URL}/sites?search=*`, {
    method: 'GET',
    headers,
  })

  const allSites = await sites.json()

  return allSites.value?.map((site: Site) => ({
    ...site,
    type: LevelEnum.SITE,
  }))
}

export const GetDrives = async (props: IMSALProps): Promise<IMSGraphDrive[]> => {
  const { siteId } = props

  const accessToken = await getCachedMSToken()
  if (!accessToken) return []

  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const drives = await fetch(`${GRAPH_URL}/sites/${siteId}/drives`, {
    method: 'GET',
    headers,
  })

  const allDrives = await drives.json()

  return allDrives.value.map((drive: Drive) => {
    return {
      id: drive.id,
      name: drive.name,
      type: LevelEnum.DRIVE,
      lastModifiedDateTime: drive.lastModifiedDateTime,
    }
  })
}

export const GetFilesRoot = async (props: IMSALProps) => {
  const { driveId } = props

  const accessToken = await getCachedMSToken()
  if (!accessToken) return []

  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const response = await fetch(`${GRAPH_URL}/drives/${driveId}/root/children`, {
    method: 'GET',
    headers,
  })

  if (!response.ok) {
    const errorText = await response.text()
    throw new Error(`Error fetching files: ${response.status} ${response.statusText} ${errorText}`)
  }

  const allFiles = await response.json()

  if (!allFiles.value) {
    throw new Error('No files found in the root directory.')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return allFiles.value.map((file: any) => {
    return {
      id: file.id,
      name: file.name,
      type: file.folder ? LevelEnum.FOLDER : LevelEnum.FILE,
      lastModifiedDateTime: file.lastModifiedDateTime,
    }
  })
}

export const GetFileContent = async (props: IMSALProps) => {
  const { driveId, itemId } = props

  const accessToken = await getCachedMSToken()

  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const fileContent = await fetch(`${GRAPH_URL}/drives/${driveId}/items/${itemId}/content`, {
    method: 'GET',
    headers,
  })

  const reader = fileContent?.body?.getReader()
  const decoder = new TextDecoder()

  let result = ''

  if (!reader) return result

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { done, value } = await reader.read()
    if (done) break
    result += decoder.decode(value, { stream: true })
  }

  result += decoder.decode()
  return result
}

export const GetMSFile = async (props: IMSALProps) => {
  const { driveId, itemId } = props

  const accessToken = await getCachedMSToken()

  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const fileContentUrl = `${GRAPH_URL}/drives/${driveId}/items/${itemId}/content`

  try {
    const response = await fetch(fileContentUrl, {
      method: 'GET',
      headers,
    })

    if (!response.ok) throw Error('Error getting MS Blob')
    const blob = await response.blob()
    return blob
  } catch (error) {
    console.error('Error fetching file content:', error)
    return null
  }
}

export const GetFilesFolder = async (props: IMSALProps) => {
  const { driveId, itemId } = props

  const accessToken = await getCachedMSToken()
  if (!accessToken) return []

  const headers = new Headers()
  headers.append('Authorization', `Bearer ${accessToken}`)

  const folderFiles = await fetch(`${GRAPH_URL}/drives/${driveId}/items/${itemId}/children`, {
    method: 'GET',
    headers,
  })

  const allFiles = await folderFiles.json()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return allFiles.value.map((file: any) => {
    return {
      id: file.id,
      name: file.name,
      type: file.file ? LevelEnum.FILE : LevelEnum.FOLDER,
      lastModifiedDateTime: file.lastModifiedDateTime,
    }
  })
}
