import { FC, useState, useContext } from 'react'
import { useSetRecoilState } from 'recoil'
import { Icon, Pill, ThemeContext, ThemeEnum } from '@aurecon-creative-technologies/styleguide'
import { dateToString } from '../helpers/utils'
import { IQuestion } from '../models/IQuestionModels'
import { RatingEnum } from '../enums/RatingEnum'
import { CurrentQuestion, ShowSeeQuestionModal } from '../stores/AppStore'
import { feedbackTypes } from '../helpers/feedbackTypeToLabel'

import Style from '../styles/ChatFeedback.module.sass'

interface IChatFeedbackDetailsProps {
  question: IQuestion
}

const ChatFeedback: FC<IChatFeedbackDetailsProps> = (props) => {
  const setCurrentQuestion = useSetRecoilState(CurrentQuestion)
  const setShowSeeQuestionModal = useSetRecoilState(ShowSeeQuestionModal)
  const { theme } = useContext(ThemeContext)

  const { question } = props
  const [open, setOpen] = useState(false)
  const { feedback } = feedbackTypes(question.feedbackType || 0)

  const handleSeeQuestion = () => {
    setCurrentQuestion(question)
    setShowSeeQuestionModal(true)
  }

  const positive = question.rating === RatingEnum.POSITIVE

  return (
    <div className={Style.feedback}>
      <div className={Style.left}>
        <Icon type={positive ? 'thumb_up' : 'thumb_down'} colour={positive ? 'green' : 'red'} />
      </div>
      <div className={Style.right}>
        <div className={Style.question}>{question.question}</div>
        <div className={Style.details}>
          {feedback.map((f) => {
            return (
              <Pill key={f} colour={theme === ThemeEnum.LIGHT ? 1 : 2}>
                {f}
              </Pill>
            )
          })}
        </div>
        <div className={open ? Style.expanded : Style.collapsed}>
          <div className={Style.text}>{question?.feedback}</div>
          <div className={Style.more} onClick={() => setOpen((val) => !val)} role='none'>
            {open ? 'less' : 'more'}
          </div>
        </div>
        {open && (
          <>
            <div className={Style.date}>Date: {dateToString(question.createdAt || '', 'dd/MM/yyyy HH:mm')}</div>
            <div className={Style.link} onClick={handleSeeQuestion} role='none'>
              See full response
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ChatFeedback
