import { FC } from 'react'
import { Dropdown, IDropdownItemProps } from '@aurecon-creative-technologies/styleguide'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import Style from '../../styles/common/RecallDropdown.module.sass'
import classNames from 'classnames'

export interface IRecallDropdownProps {
  chatType: number
  open?: 'down' | 'up'
  label?: string
  placeholder?: string
  selectedItem?: string | number
  items: IDropdownItemProps[]
  onSelectItem?: (item: string | number) => void
  cssClass?: string
  disabled?: boolean
  required?: boolean
  selectedMultipleItems?: (string | number)[]
  onSelectMultipleItems?: (items: (string | number)[]) => void
  selectAllItemLabel?: string
  unselectAllItemLabel?: string
}

export const SELECT_ALL_LABEL = 'Select All'
export const UNSELECT_ALL_LABEL = 'Unselect All'

const RecallDropdown: FC<IRecallDropdownProps> = ({
  chatType,
  open,
  label,
  placeholder,
  selectedItem,
  items,
  onSelectItem,
  cssClass,
  disabled,
  required,
  selectedMultipleItems,
  onSelectMultipleItems,
  selectAllItemLabel,
  unselectAllItemLabel,
}) => {
  const chatTypeClasses = () => {
    return classNames(cssClass, {
      [Style.bamboo]: chatType === ChatTypeEnum.BAMBOO,
      [Style.cra]: chatType === ChatTypeEnum.CUSTOM_RECALL_APP,
      [Style.recall]: chatType === ChatTypeEnum.RECALL,
    })
  }

  return (
    <Dropdown
      open={open}
      label={label}
      items={items}
      selectedItem={selectedItem}
      onSelectItem={onSelectItem}
      selectedMultipleItems={selectedMultipleItems}
      onSelectMultipleItems={onSelectMultipleItems}
      cssClass={chatTypeClasses()}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      multiple={!!selectedMultipleItems}
      toggleAllItem={
        !selectedMultipleItems?.length
          ? selectAllItemLabel ?? SELECT_ALL_LABEL
          : unselectAllItemLabel ?? UNSELECT_ALL_LABEL
      }
    />
  )
}

export default RecallDropdown
