import { ChatTypeEnum } from '../enums/ChatTypeEnum'
import { FeatureFlagEnum } from '../enums/FeatureFlagEnum'
import { AppRoute } from '../enums/AppRouteConstants'

interface ChatStandardConfig {
  appRoute: string
  chatTypeEnum: (typeof ChatTypeEnum)[keyof typeof ChatTypeEnum]
  featureFlag: (typeof FeatureFlagEnum)[keyof typeof FeatureFlagEnum]
  title: string
  appInsightsName: string
  trackPageView: string
  descriptions: string[]
}

export const chatStandardConfig: Record<string, ChatStandardConfig> = {
  [AppRoute.BHP_STANDARDS]: {
    appRoute: AppRoute.BHP_STANDARDS,
    chatTypeEnum: ChatTypeEnum.BHP_STANDARDS,
    featureFlag: FeatureFlagEnum.ShowBHPStandardsApp,
    title: 'BHP Standards',
    appInsightsName: 'BHPStandards',
    trackPageView: 'BHP Standards Home',
    descriptions: ['bhp_desc', 'bhp_desc2', 'bhp_desc3'],
  },
  [AppRoute.RIO_TINTO_STANDARDS]: {
    appRoute: AppRoute.RIO_TINTO_STANDARDS,
    chatTypeEnum: ChatTypeEnum.RIO_TINTO_STANDARDS,
    featureFlag: FeatureFlagEnum.ShowRioTintoStandardsApp,
    title: 'Rio Tinto Standards',
    appInsightsName: 'riostandards',
    trackPageView: 'Rio Tinto Standards Home',
    descriptions: ['rio_desc', 'rio_desc2', 'rio_desc3'],
  },
  [AppRoute.AUSNET_STANDARDS]: {
    appRoute: AppRoute.AUSNET_STANDARDS,
    chatTypeEnum: ChatTypeEnum.AUSNET_STANDARDS,
    featureFlag: FeatureFlagEnum.ShowAusNetStandardsApp,
    title: 'AusNet Standards',
    appInsightsName: 'ausnetstandards',
    trackPageView: 'AusNet Standards Home',
    descriptions: ['ausnet_desc', 'ausnet_desc2', 'ausnet_desc3'],
  },
}
