import { FC, useEffect } from 'react'
import { Grid, Icon } from '@aurecon-creative-technologies/styleguide'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'
import PreparingChatModal from '../components/modals/PreparingChatModal'
import { ChatSession, ChatType, QuestionFile, QuestionInput } from '../stores/AppStore'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'
import { Trans } from 'react-i18next'
import { useLanguages } from '../hooks/useLanguages'

import Style from '../styles/Home.module.sass'
import ChatSwitcher from '../components/ChatSwitcher'

const SUGGESTIONS = [
  'What tools are available to help me price an opportunity?',
  'What is a gateway review group (GRG)?',
  'Describe the approval stages for a new bid.',
]

const ChatPMHome: FC = () => {
  const setQuestionInput = useSetRecoilState(QuestionInput)
  const setChatType = useSetRecoilState(ChatType)
  const setQuestionFile = useSetRecoilState(QuestionFile)
  const { t } = useLanguages()
  const [chatSession, setChatSession] = useRecoilState(ChatSession)

  if (appInsights) appInsights.trackPageView({ name: 'PM Assistant Chat Home' })

  useEffect(() => {
    if (!chatSession) return
    setChatSession(null)
  }, [setChatSession, chatSession])

  useEffect(() => {
    setChatType(ChatTypeEnum.PM_APP)
    setQuestionFile(null)

    return () => setChatType(null)
  }, [setChatType, setQuestionFile])

  const handleSuggestion = (value: string) => {
    setQuestionInput(value)
  }

  return (
    <Page menu contentWrapper>
      <h1 className={Style.chatTitle}>PM Assistant</h1>
      <ChatSwitcher activeChat={ChatTypeEnum.PM_APP} />
      <p>PM Assistant allows users to query Aurecon’s project management standards, processes and other learnings.</p>
      <p className={Style.note}>
        <i>PM Assistant uses a custom knowledge base of selected internal documents about project management. </i>
      </p>

      <div className={Style.examples}>
        <h3>{t('recall_desc3')}</h3>
        {SUGGESTIONS.map((key) => {
          const suggestion = t(key)
          return (
            <div
              key={suggestion}
              className={Style.question}
              onClick={() => handleSuggestion(suggestion)}
              onKeyDown={() => handleSuggestion(suggestion)}
              role='none'
            >
              <Icon type='format_quote' outlined size='14px' /> {suggestion}
            </div>
          )
        })}
      </div>
      <div className={Style.examples}>
        <h3>{t('navigation_tip')}</h3>
        <Grid row>
          <div>
            <p>
              <Icon type='keyboard' outlined />
            </p>
          </div>
          <div className={Style.tip}>
            <p>
              <Trans i18nKey='navigation' />
            </p>
          </div>
        </Grid>
      </div>
      <PreparingChatModal />
    </Page>
  )
}

export default ChatPMHome
